import $ from 'jquery';
import './TweenMax.min';
{

    const lerp = (a,b,n) => (1 - n) * a + n * b;
    
    const distance = (x1,x2,y1,y2) => {
        var a = x1 - x2;
        var b = y1 - y2;
        return Math.hypot(a,b);
    };
    
    const getMousePos = (e) => {
        let posx = 0;
        let posy = 0;
        if (!e) e = window.event;
        if (e.pageX || e.pageY) {
            posx = e.pageX;
            posy = e.pageY;
        }
        else if (e.clientX || e.clientY) 	{
            posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
        }
        let parentOffset = $('.content').offset(); 
        //or $(this).offset(); if you really just want the current element's offset
        posx = e.pageX - parentOffset.left;
        posy = e.pageY - parentOffset.top;
        
        return { x : posx, y : posy }
    }
    
    let winsize;
    const calcWinsize = () => winsize = {width: window.innerWidth, height: window.innerHeight};
    calcWinsize();
    window.addEventListener('resize', calcWinsize);

    const feDisplacementMapEl = document.querySelector('feDisplacementMap');
    const elementEM = document.getElementsByTagName("em");

    class Distort {
        constructor() {
            this.initText();
            this.DOM = {
                svg: document.querySelector('svg.distort'),
                menu: document.querySelector('.main p')
            };
            
            this.DOM.imgs = $('g > image');
            this.DOM.menuLinks = document.querySelectorAll('.dist');
            this.mousePos = {x: winsize.width/2, y: winsize.height/2};
            this.lastMousePos = {
                translation: {x: winsize.width/2, y: winsize.height/2},
                displacement: {x: 0, y: 0}
            };
            this.dmScale = 0;

            this.current = -1;
            
            this.initEvents();
            requestAnimationFrame(() => this.render());
            
        }
        initEvents() {
            

            window.addEventListener('mousemove', ev => this.mousePos = getMousePos(ev));

            const mousemenuleaveFn = () => {
                TweenMax.set(this.DOM.imgs[this.current], {opacity: 0});
                TweenMax.set(elementEM, {opacity: 1});
            };
            
            this.DOM.menuLinks.forEach((item, pos) => {

                const mouseenterFn = () => {
                    if ( this.current !== -1 ) {
                        TweenMax.set(this.DOM.imgs[this.current], {opacity: 0});
                    }
                    this.current = pos;
                    if(!this.fade) {
                        TweenMax.set(this.DOM.imgs[this.current], {opacity: 1});
                        TweenMax.set(elementEM, {opacity: 0});
                    }
                };
                item.addEventListener('mouseenter', mouseenterFn);
                item.addEventListener('mouseleave', mousemenuleaveFn);
            });
            
            this.DOM.menu.addEventListener('mouseleave', mousemenuleaveFn);
        }
        initText() {
            const arrayWord = [
                "Electrifying",
                "idea", 
                "brightness", 
                "UltraSuperNew,", 
                "Tokyo", 
                "Singapore,", 
                "strike."
            ];
            let ideaCheck = 0;
            $( 'p:not(.noglitch)' ).each(function() {
                const str = $(this).html();
                const res = str.split(" ");
                let textHtml = '';
                for (let index = 0; index < res.length - 1; index++) {
                    if (arrayWord.indexOf(res[index]) > -1) {
                        if (res[index] === "idea") {
                            if (ideaCheck === 1) {
                                res[index] = `<span class="dist">${res[index]}</span> `;
                            } else {
                                res[index] = `${res[index]} `;
                                ideaCheck = 1;
                            }
                        } else {
                            res[index] = `<span class="dist">${res[index]}</span> `;
                        }
                        
                    } else if(Math.round(Math.random()) === 1) {
                        res[index] = `<em>${res[index]}</em> `;  
                    } else {
                        res[index] = `${res[index]} `;
                    }
                    textHtml += res[index];
                }
                $(this).html(textHtml);
            });
        }
        render() {
            const sizeImage = 200;
            this.lastMousePos.translation.x = lerp(this.lastMousePos.translation.x, this.mousePos.x, 0.1);
            this.lastMousePos.translation.y = lerp(this.lastMousePos.translation.y, this.mousePos.y, 0.1);
            this.DOM.svg.style.transform = `translateX(${(this.lastMousePos.translation.x - sizeImage)}px) translateY(${this.lastMousePos.translation.y - sizeImage}px)`;
            
            // Scale goes from 0 to 100 for mouseDistance values between 0 to 100
            this.lastMousePos.displacement.x = lerp(this.lastMousePos.displacement.x, this.mousePos.x, 0.1);
            this.lastMousePos.displacement.y = lerp(this.lastMousePos.displacement.y, this.mousePos.y, 0.1);
            const mouseDistance = distance(this.lastMousePos.displacement.x, this.mousePos.x, this.lastMousePos.displacement.y, this.mousePos.y);
            this.dmScale = Math.min(mouseDistance, 100);  
            feDisplacementMapEl.scale.baseVal = this.dmScale;

            requestAnimationFrame(() => this.render());
        }
    }

    new Distort();
}
