import $ from 'jquery';
import './TweenMax.min';

$(function() {
    const screenWidth = $('.header').outerWidth();
    const screenHeight = $('.header').outerHeight();
    const widthLogo = $('.line__picture').outerWidth();
    const heightLogo = $('.line__picture').outerHeight();

    // Sub Logo
    const widthLogoSub = $('.line__picture--sub').outerWidth();

    const fullWidthLogo = widthLogo + widthLogoSub;
    
  
    // Get the number of items we can put from left to right
    const numberWidthItems = Math.ceil(screenWidth / fullWidthLogo);
    const item = $('.line__picture');
    const itemSub = $('.line__picture--sub');
    for (let index = 0; index < numberWidthItems + 1; index++) {
      const itemClone = item.clone();
      const itemSubClone = itemSub.clone();
      $( '.line' ).append( itemClone, itemSubClone);
    }
    // Change the width of the container;
    
    $('.line').width(fullWidthLogo * (numberWidthItems));
    
  
    // Get the number of lines we can put from top to bottom
    const numberLines = Math.ceil(screenHeight / heightLogo);
    const f = $('.line');
    for (let index = 0; index < numberLines - 1; index++) {
      f.clone().insertAfter(f); 
    }
    
    $( '.line__picture, .line__picture--sub' ).each(function() {
      setTimeout(() => {
        $(this).addClass('show');
      }, Math.floor(Math.random() * 2000) + 200 );
    });

}); 

  $(function() {
    const material = new Blotter.RollingDistortMaterial();
    const material2 = new Blotter.RollingDistortMaterial();
    let fontSizeCan = 58;
    if (window.innerWidth > 768) {
      fontSizeCan = 72;
    }

    $( '.title' ).each(function() {
  
      const str = $(this).html();
      const res = str.split(" ");
  
      // More random
      material.uniforms.uSineDistortSpread.value = Math.random() * 50 + 20;
      material.uniforms.uSineDistortCycleCount.value = 0;
      material.uniforms.uSineDistortAmplitude.value = Math.random() * 3 + 1;
      material.uniforms.uNoiseDistortVolatility.value = (Math.random() * 5) + 5;
      material.uniforms.uDistortPosition.x = Math.random() * 2;
      material.uniforms.uDistortPosition.y = Math.random() * .8 + .1;
      material.uniforms.uNoiseDistortAmplitude.value = Math.random() * .2;
      material.uniforms.uSpeed.value = Math.random() * 1 + .5;

      material2.uniforms.uSineDistortSpread.value = 0;
      material2.uniforms.uSineDistortCycleCount.value = 0;
      material2.uniforms.uSineDistortAmplitude.value = 0;
      material2.uniforms.uNoiseDistortVolatility.value = 0;
      material2.uniforms.uDistortPosition.x = 0;
      material2.uniforms.uDistortPosition.y = 0;
      material2.uniforms.uNoiseDistortAmplitude.value = 0;
      material2.uniforms.uSpeed.value = 0;
  
      $(this).html('');
      for (let index = 0; index < res.length; index++) {
        const text = new Blotter.Text(res[index].toUpperCase(), {
          family : 'industry',
          weight: 900,
          size : fontSizeCan,
          fill : "#FAFA01",
          padding: 0,
          paddingLeft: 5,
          paddingRight: 5,
          leading: 1,
        });
    
        const blotter = new Blotter(material, { texts : text });
        const blotter2 = new Blotter(material2, { texts : text });
        const scope = blotter.forText(text);
        const scope2 = blotter2.forText(text);
        const elementToAdd = '<div></div>';
        $( elementToAdd ).appendTo( $( this ) );
        scope.appendTo($(this).children('div').last());
        scope2.appendTo($(this).children('div').last());
      }
    
    });

});
